import axios from '@/libs/axios'
import {SET_APP_DATA} from "@/auth/utils";

export default {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem('userData')),
        users: [],
        permissionsUserLogue: JSON.parse(localStorage.getItem('permissions')),
        access_token: localStorage.getItem('access_token') ,
        refresh_token: localStorage.getItem('refresh_token') ,
        urlLogo: localStorage.getItem('logoFront'),
        urlWallpaper: localStorage.getItem('wallpaperUrl'),
    },
    mutations: {
        SET_AUTH_USER(state, user){
            state.user = user 
        },
        GET_USERS(state, payload){
            state.users = payload
        },
        SET_ACCESS_TOKEN(state, access_token){
            state.access_token = access_token 
        },
        SET_REFRESH_TOKEN(state, refresh_token){
            state.refresh_token = refresh_token
        },
        SET_PERMISSIONS_USER_LOGUE(state, payload){
            state.permissionsUserLogue = payload
        },
        SET_LOGO(state, payload){
            state.urlLogo = payload
        },
        SET_WALLPAPE_URL(state, payload){
            state.urlWallpaper = payload
        }

    },
    actions: {
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('auth/signin', credentials)
                .then(res => {
                    SET_APP_DATA(res.data, true)
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        register(state, form) {
            return new Promise((resolve, reject) => {
                axios.post('register', form)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        clients({ commit, getters}) {
            return new Promise((resolve, reject) => {
                axios.get('clients')
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        users({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get('users')
                .then(res => {
                    commit('GET_USERS', res.data)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        showUser({ commit, getters }, id) {
            return new Promise((resolve, reject) => {
                axios.get('users/' + id)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        //JeanRosa, Metodo para UserHistory
        fetchsTransactions(ctx, queryParams){
            return new Promise ((resolve, reject)=>{
            axios
                .get(`user-historical`, { params: queryParams })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchUsersByName(ctx, queryParams) {
            return new Promise((resolve, reject) => {
              axios
                .get('/users/showByQ/' + queryParams )
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
        store({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('users', formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        update({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.put(`users/${formData._id}`, formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        destroy({ commit, getters }, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`users/${id}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        // blockGameUser({ commit, getters }, params) {
        //     return new Promise((resolve, reject) => {
        //         axios.post(`restrictions/${params.userId}`, params)
        //         .then(res => {
        //             resolve(res.data)
        //         })
        //         .catch(error => {
        //             reject(error.response.data)
        //         })
        //     })
        // },
        blockProductOrGameUser({ commit, getters }, params) {
            return new Promise((resolve, reject) => {
                axios.patch(`restrictions/${params.userId}`, params)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getGamesByProduct({ commit, getters }, idProduct) {
            return new Promise((resolve, reject) => {
                axios.get(`available-games/byProduct/${idProduct}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getProductsGamesLocksUser({ commit, getters }, userId) {
            return new Promise((resolve, reject) => {
                axios.get(`showBlocksUser/${userId}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getUserProductsInfo({ commit, getters }, userId) {
            return new Promise((resolve, reject) => {
                axios.get(`users/products-games/${userId}`)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error.response.data)
                    })
            })
        }
    },
    getters: {
        productsUserOptions(state, getters, rootState, rootGetters) {
            if(state.user.typeUser == "Root"){
                return rootState.whitelabelCurrencyNabvar.whitelabel?.products
            }else{
                return state.user.products
            }
        },
        permissionsUserLogue(state, getters, rootState, rootGetters) {
            return state.permissionsUserLogue
        },
        typeUser(state) {
            return state.user.typeUser
        },
        getUserData(state){
            return state.user;
        }
    }
}